import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Header.css"; // Import your CSS file for custom styles
import { motion } from "framer-motion"



export default function Header() {
    const [visible, setVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [activeNavItem, setActiveNavItem] = useState(false);


    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setVisible(currentScrollPos <= 0 || currentScrollPos < prevScrollPos);
        setPrevScrollPos(currentScrollPos);
    };


    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    const handleSetActive = (to) => {
        setActiveNavItem(to);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <div id="navbar" className={`container-fluid top-0 position-fixed w-100 ${visible ? "visible" : "hidden"} hover-show`}>
            <nav className="navbar navbar-dark fixed-top navbar-expand-md" >
                

                    <motion.div whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}>
                        <a className="navbar-brand" onClick={scrollToTop} style={{ cursor: "pointer" }}>
                            <Link
                                to="start"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={10}
                                onSetActive={() => handleSetActive("start")}
                            >
                                <img src="assets/img/logo.png" width={"130px"} alt="Logo"></img>
                            </Link>
                        </a>
                    </motion.div>

                    <motion.button
                        hileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </motion.button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav d-flex justify-content-center" style={{ marginLeft: "auto" }}>

                            <motion.li whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} className={`nav-item ${activeNavItem === "start" ? "active" : ""}`}>
                                <Link
                                    to="start"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={10}
                                    onSetActive={() => handleSetActive("start")}
                                >
                                    <a className="nav-link" aria-current="page" href="#">
                                        <h5 className="nav-li">Start /{">"}</h5>
                                    </a>
                                </Link>
                            </motion.li>

                            <motion.li whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} className={`nav-item ${activeNavItem === "about" ? "active" : ""}`}>
                                <Link
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={10}
                                    onSetActive={() => handleSetActive("about")}
                                >
                                    <a className="nav-link" aria-current="page" href="#">
                                        <h5 className="nav-li">About /{">"}</h5>
                                    </a>
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} className={`nav-item ${activeNavItem === "projects" ? "active" : ""}`}>
                                <Link
                                    to="projects"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={10}
                                    onSetActive={() => handleSetActive("projects")}
                                >
                                    <a className="nav-link" aria-current="page" href="#">
                                        <h5 className="nav-li">Projects /{">"}</h5>
                                    </a>
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} className={`nav-item ${activeNavItem === "contact" ? "active" : ""}`}>
                                <Link
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={10}
                                    onSetActive={() => handleSetActive("contact")}
                                >
                                    <a className="nav-link" aria-current="page" href="#">
                                        <h5 className="nav-li">Contact /{">"}</h5>
                                    </a>
                                </Link>
                            </motion.li>
                        </ul>
                    </div>
                
            </nav>
        </div>
    );
}
