import React, { useState, useEffect, useRef } from "react";
import "./Project.css"; // Import your CSS file for custom styles
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useInview, useAnimation, useScroll, useSpring, useTransform } from "framer-motion";
import RevealWithSlide from "../../utils/RevealWithSlide";
import Reveal from "../../utils/Reveal";
import { useMedia } from 'react-use';
import Slider from "./Slider";

export default function Project() {
    return (
        <div className="mx-auto col-sm-7 col-md-7 col-9">
            <div className="timeline alt"></div>
            <small class="section-bullet section-title project-bullet-title alt">Project /{">"}</small>

            <div className="project-container">
                <div className="web-project-container">
                    <div class="drag-wrapper">

                        <div class="guide">
                            <svg xmlns="http://www.w3.org/2000/svg" class="mouse" width="40" height="58" viewBox="0 0 40 58">
                                <g fill="#FFFFFF" fill-rule="evenodd">
                                    <polygon fill-rule="nonzero" points="38.275 23 9.045 23 9.045 25 38.275 25" />
                                    <polygon fill-rule="nonzero" points="22.667 10.139 22.667 23.5 24.667 23.5 24.667 10.139" />
                                    <path fill-rule="nonzero" d="M23.66,55.3612689 C31.1635892,55.3612689 37.2751259,49.7250913 37.2751259,42.8664131 L37.2751259,23.5804576 C37.2751259,16.7673171 31.1551908,11.1387311 23.66,11.1387311 C16.1648092,11.1387311 10.0448741,16.7673171 10.0448741,23.5804576 L10.0448741,42.9195424 C10.0448741,49.7326829 16.1648092,55.3612689 23.66,55.3612689 Z M23.66,57.3612689 C15.0958825,57.3612689 8.04487413,50.8763623 8.04487413,42.9195424 L8.04487413,23.5804576 C8.04487413,15.6236377 15.0958825,9.13873106 23.66,9.13873106 C32.2241175,9.13873106 39.2751259,15.6236377 39.2751259,23.5804576 L39.2751259,42.8664131 C39.2751259,50.8674505 32.2336144,57.3612689 23.66,57.3612689 Z" />
                                    <path class="mouse-small-click" fill-rule="nonzero" d="M6.57820747,20.1259121 C6.57820747,13.448275 14.2805453,6.82054924 21.9266667,6.82054924 L21.9266667,4.82054924 C13.2371205,4.82054924 4.57820747,12.2713911 4.57820747,20.1259121 L6.57820747,20.1259121 Z" />
                                    <path class="mouse-large-click" fill-rule="nonzero" d="M2.37820747,21.1259121 C2.37820747,11.5705676 12.1430608,2.63873106 22.9266667,2.63873106 L22.9266667,0.638731061 C11.0763312,0.638731061 0.378207465,10.4242229 0.378207465,21.1259121 L2.37820747,21.1259121 Z" />
                                    <path class="mouse-click" d="M9.64178282,24.1387311 C9.64178282,24.1387311 8.4864369,9.65181073 24.0836068,10.1513597 C24.0836068,14.1477515 24.0836068,23.6391821 24.0836068,23.6391821 L24.0836068,24.1387311 L9.64178282,24.1387311 Z" />
                                </g>
                            </svg>
                            <p>Drag to explore</p>
                        </div>

                    </div>
                    <Reveal>
                        <Slider items={web} />
                    </Reveal>
                </ div >
                <div className="mobile-project-container">
                    <Reveal>
                        <Slider items={mobile} />
                    </Reveal>
                </ div >
                <div className="games-project-container">
                    <Reveal>
                        <Slider items={games} />
                    </Reveal>
                </ div >
            </div>
        </div>


    )
}

const web = [
    {
        "title": "Realtime Collaborative Whiteboard \"MemoZ\"",
        "image": "assets/img/web/memoz.png",
        "desc": "MemoZ is a real-time collaborative whiteboard application that allows users to draw, write, and collaborate with others in real-time. With a simple and intuitive interface, MemoZ makes it easy for users to create and share ideas, sketches, and designs with others. This repository contains the source code and necessary files to run the MemoZ application.",
        "link": "https://github.com/DaoDuyThai/MemoZ",
        "linkText": "View Project",
        "year": "2024",
        "technology": ["TypeScript", "JavaScript", "NextJS", "TailwindCSS", "Shadcn", "Convex", "Clerk", "Liveblocks", "Stripe"],
    },
    {
        "title": "Mentor Connecting Platform \"CodeCoach\"",
        "image": "assets/img/web/codecoach.png",
        "desc": "CodeCoach is a platform that connects aspiring developers (mentees) with experienced professionals (mentors) to provide guidance, support, and mentorship in various programming fields. With customizable interactive interface, CodeCoach makes finding tutors online convenient and enjoyable.",
        "link": "https://github.com/DaoDuyThai/CodeCoach",
        "linkText": "View Project",
        "year": "2023",
        "technology": ["JSP", "Servlet", "JSTL", "SQL Server", "SASS", "AJAX", "Bootstrap", "Graphql", "SocketIO"],
    },
    {
        "title": "Food-Ordering Platform \"HolaFood\"",
        "image": "assets/img/web/holafood.png",
        "desc": "A comprehensive food ordering platform designed to connect hungry customers with the best local restaurants in the area of Hoa Lac - Hanoi. With a user-friendly interface, advanced search and filtering options, and secure payment processing, HolaFood makes ordering food online convenient and enjoyable.",
        "link": "https://github.com/DaoDuyThai/HolaFood/",
        "linkText": "View Project",
        "year": "2023",
        "technology": ["JSP", "Servlet", "JSTL", "SQL Server", "SASS", "AJAX", "Bootstrap"],
    },
    {
        "title": "Movie App \"MovieHub\"",
        "image": "assets/img/web/moviehub.png",
        "desc": "Moviehub is a free movie watching website designed to provide users with a seamless streaming experience. With a vast library of movies, TV shows, and documentaries, Moviehub offers a diverse selection of content for viewers of all ages.",
        "link": "https://github.com/DaoDuyThai/MovieHub",
        "linkText": "View Project",
        "year": "2023",
        "technology": ["React", "Stripe", "SASS"],
    },
    {
        "title": "Social Media \"OnlyFuns\"",
        "image": "assets/img/web/onlyfuns.png",
        "desc": "OnlyFuns is a social media platform that allows users to share photos, videos, and stories with their friends and followers. With a user-friendly interface, advanced privacy settings, and real-time notifications, OnlyFuns makes connecting with others online fun and engaging.",
        "link": "https://github.com/DaoDuyThai/OnlyFuns_Frontend",
        "linkText": "View Project",
        "year": "2023",
        "technology": ["React", "Node.js", "MongoDB", "Vite", "ESLint", "JWT", "Cloudinary"],
    },
    {
        "title": "Sample \"Portfolio_V2\"",
        "image": "assets/img/web/portfolio_v2.png",
        "desc": "Portfolio_V2 is a minimalist yet elegant repository showcasing a personal portfolio website built entirely with pure HTML and CSS. This repository serves as a perfect starting point for individuals looking to create their own portfolio to showcase their skills, projects, and experiences in a clean and professional manner.",
        "link": "https://www.youtube.com/watch?v=KX-ZSTdIrdU&t=87s",
        "linkText": "View Project",
        "year": "2024",
        "technology": ["HTML", "CSS"],
    },
    {
        "title": "Bootstrap Theme Kit",
        "image": "assets/img/web/bootstrap-theme-kit.png",
        "desc": "A starter project for making Bootstrap 5 themes with SASS. This is simple starter project to help get started quickly when making a custom Bootstrap theme.",
        "link": "https://github.com/DaoDuyThai/BootstrapThemeKit",
        "linkText": "View Project",
        "year": "2023",
        "technology": ["HTML", "Bootstrap", "SASS"],
    }

]

const mobile = [
    {
        "title": "Alarm Challenger \"WakeUp\"",
        "image": "assets/img/mobile/wakeup.png",
        "desc": "WakeUp is a an Android alarm clock application developed in Java using Android Studio. It is designed to help users wake up on time by providing various challenges or tasks that must be completed before the alarm can be turned off. These challenges are meant to ensure that the user is fully awake before disabling the alarm. This repository contains the source code and necessary files to run the WakeUp application.",
        "link": "https://github.com/DaoDuyThai/WakeUp",
        "linkText": "View Project",
        "year": "2024",
        "technology": ["Java", "Android Studio", "SQLite"],
    },
]

const games = [

    {
        "title": "Pacman",
        "image": "assets/img/games/pacman.png",
        "desc": "Get ready to become a chomping champion! This game throws you into the iconic world of Pac-Man. Navigate a maze filled with delicious dots, gobble them all up, and avoid those pesky ghosts! Can you clear the level and rack up the highest score?",
        "link": "https://github.com/DaoDuyThai/Pacman",
        "linkText": "View Project",
        "year": "2024",
        "technology": ["HTML", "JavaScript"],
    },

    {
        "title": "Tetris",
        "image": "assets/img/games/tetris.png",
        "desc": "The shapes keep falling, can you handle the pressure? This game brings the classic Tetris experience to life. Rotate and strategically place tetrominoes (those cool shapes) to clear lines and avoid a blocky build-up. How high can you climb before the tower reaches the sky?",
        "link": "https://github.com/DaoDuyThai/Tetris",
        "linkText": "View Project",
        "year": "2024",
        "technology": ["HTML", "JavaScript"],
    },

    {
        "title": "Vietnamese Traditional Game",
        "image": "assets/img/games/vietnamese-traditional-game.png",
        "desc": "The game featured in this project is a traditional Vietnamese game, the name of which can be specified as 'Xóc Đĩa'. In this game, players will engage in a set of activities that mimic the rules and mechanics of the actual traditional game. The game's objective and rules will be outlined in the project documentation or within the code itself.",
        "link": "https://github.com/DaoDuyThai/VietnameseTraditionalGame",
        "linkText": "View Project",
        "year": "2021",
        "technology": ["HTML", "CSS", "JavaScript"],
    },
    {
        "title": "Simple JavaScript Games",
        "image": "assets/img/games/simple-games.png",
        "desc": "These are some simple games that I made during the process of learning JavaScript. These includes: Ping Pong, Snake Game, Tic Tac Toe, Rock-Scissors-Paper,... You can visit my Github repo to discover more about how I learnt JavaScript and the Projects within.",
        "link": "https://github.com/DaoDuyThai/JavaScript_Learning/",
        "linkText": "View Project",
        "year": "2021",
        "technology": ["HTML", "CSS", "JavaScript"],
    },

]
