import React, { useState, useEffect } from "react";
import "./About.css"; // Import your CSS file for custom styles
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useInview, useAnimation } from "framer-motion";
import RevealWithSlide from "../../utils/RevealWithSlide";
import Reveal from "../../utils/Reveal";

const workExperiences = [
    {
        year: "2023-now",
        position: "ERP/MES Developer at InfoSolution"
    },
    {
        year: "2021-now",
        position: "Freelance UX/UI designer & web developer"
    },
    {
        year: "2021-now",
        position: "Content Creator at ",
        link: "https://www.youtube.com/@codewithdt/",
        linkText: "Duy Thái"
    },
    {
        year: "2021-now",
        position: "Tech Blogger at ",
        link: "https://codewithdt.com/",
        linkText: "CodeWithDT"
    },
    {
        year: "2019-now",
        position: "Freelance English Private Tutor"
    },
    {
        year: "2023-2024",
        position: "President of ",
        link: "https://www.facebook.com/photo/?fbid=596708805820720&set=a.543833874441547",
        linkText:"FPTU English Club"
    },
];

const programmingLanguages = [
    'Java', 'Python', 'C++', 'C', 'Pascal', 'Delphi', 'PHP', 'C#', 'TypeScript'
];

const databases = [
    'MySQL', 'MongoDB', 'ORM', 'OracleSQL', 'SQLDeveloper', 'RESTful API'
]

const webDevelopment = [
    'HTML/CSS/JS', 'SASS', 'SCSS/Less', 'Bootstrap/Tailwind', 'Webpack/Vite', 'npm/yarn', 'React', 'Vue.js', 'Node.js', 'Express', 'Next.js', 'Web-extensions', 'Web Sockets', 'Firebase', 'JSP', 'JSTL', 'JQuery', 'AJAX'
]

const appDevelopment = [
    'Android Studio', 'Flutter', 'Kotlin', 'iOS', 'Unity', '.NET', 'Electron'
]

const others = [
    'Docker', 'GIT/SVN', 'Motion Design', 'UX/UI'
]

const designTools = [
    'Photoshop', 'Illustrator', 'After Effects', 'Premiere Pro', 'Adobe XD', 'Figma', 'InVision'
]

const certificates = [
    {
        name: 'IELTS',
        description: '8.0',
    },
    {
        name: 'Agile Software Development',
        link: 'https://www.coursera.org/account/accomplishments/certificate/KPK478ZVEPKZ',
        description: ''
    },
    {
        name: 'Engineering Practices for Building Quality Software',
        link: 'https://www.coursera.org/account/accomplishments/certificate/MWLM3MQ7YGVC',
        description: ''
    },
    {
        name: 'Lean Software Development',
        link: 'https://www.coursera.org/account/accomplishments/certificate/XU6SMSZJXEZ3',
        description: ''
    },
    {
        name: 'Software Development Processes and Methodologies',
        link: 'https://www.coursera.org/account/accomplishments/certificate/DD456CPY95HM',
        description: ''
    },
    {
        name: 'Computer Communications Specialization',
        link: 'https://www.coursera.org/account/accomplishments/specialization/certificate/4ECFM86RAQJ7',
        description: ''
    },
    {
        name: 'TCP/IP and Advanced Topics',
        link: 'https://www.coursera.org/account/accomplishments/certificate/ZVNHTTY39PYZ',
        description: ''
    },
    {
        name: 'Packet Switching Networks and Algorithms',
        link: 'https://www.coursera.org/account/accomplishments/certificate/64VR7Z5UM3HJ',
        description: ''
    },
    {
        name: 'Peer-to-Peer Protocols and Local Area Networks',
        link: 'https://www.coursera.org/account/accomplishments/certificate/WL4J3M7TMPJG',
        description: ''
    },
    {
        name: 'Fundamentals of Network Communication',
        link: 'https://www.coursera.org/account/accomplishments/certificate/FXT8ML3G9WVL',
        description: ''
    },
    {
        name: 'Champion',
        link: 'https://www.facebook.com/100063749102902/videos/352224575800138',
        description: 'Vietnam Amazing Student issued by VSHR'
    },
    {
        name: 'Creative Invention',
        description: 'Vietnamese Union of Science & Technology'
    }
]

export default function AboutDetails() {
    return (
        <div id="about-details" class="container-fluid section">
            <div class="timeline alt"></div>
            <div class="row align-self-top w-100 align-items-start justify-content-center">
                <div class="pt-0 offseted col-sm-9 col-md-9 offset-sm-2 col-10 offset-1">
                    <div class="mt-3">

                        <div class="code-wrp">
                            <div data-line-nr="01" class="code-l">
                                <div class="methods">class</div> <span class="propname">Đào Duy Thái</span> {"{"}
                            </div>
                            <div data-line-nr="02" class="code-l">
                                <div class="dot">··</div>
                                <div class="comment">// I can, because I did.</div>
                            </div>
                            <div data-line-nr="03" class="code-l">
                                <div class="dot">··</div>
                                <div class="comment">// My vast variety of skills is continuously expanding.</div>
                            </div>
                            <div data-line-nr="04" class="code-l">
                                <div class="dot">··</div>
                                <div class="methods">constructor</div>{"() {"}
                            </div>

                            <div data-line-nr="05" class="code-l">
                                <Reveal>
                                    <div class="dot">····</div><span class="scope">this</span>.<span class="prop2">name</span> <span
                                        class="scope">=</span> <span class="string">'Đào Duy Thái'</span>
                                </Reveal>
                            </div>
                            <div data-line-nr="06" class="code-l">
                                <Reveal>
                                    <div class="dot">····</div><span class="scope">this</span>.<span
                                        class="prop2">dayOfBirthTimestamp</span> <span class="scope">=</span> <span
                                            class="number">1063818000</span><span className="comment"> //18th Sept 2003</span>
                                </Reveal>

                            </div>

                            <div data-line-nr="07" class="code-l">
                                <Reveal>
                                    <div class="dot">····</div><span class="scope">this</span>.<span class="prop2">email</span> <span
                                        class="scope">=</span> <span class="string">'daoduythai.business@gmail.com'</span>
                                </Reveal>
                            </div>
                            <div data-line-nr="08" class="code-l">
                                <div class="dot">··</div>{"}"}
                            </div>


                            {/* word experience start */}
                            <div data-line-nr="09" class="code-l">
                                <div class="dot">··</div><span class="propname">workExperience</span>{"() {"}
                            </div>
                            <div data-line-nr="10" class="code-l">

                                <div class="dot">····</div>
                                <div class="methods">return</div> {"["}
                            </div>
                            {
                                workExperiences.map((workExperience, index) => (
                                    <>
                                        <div data-line-nr={index + 11} class="code-l">
                                            {
                                                workExperience.link == null ?
                                                    <Reveal delay={index / 4}>
                                                        <div class="dot">······</div>{"{"}<span class="string">'{workExperience.year}'</span> : <span class="string">
                                                            '{workExperience.position}'</span>{"}"},
                                                    </Reveal>
                                                    :
                                                    <Reveal delay={index / 4}>
                                                        <div class="dot">······</div>{"{"}<span class="string">'{workExperience.year}'</span> : <span class="string">
                                                            '{workExperience.position} <a rel="noreferrer" target="_blank" style={{ color: "inherit" }} href={workExperience.link}>{workExperience.linkText}</a>'</span>{"}"},
                                                    </Reveal>
                                            }
                                        </div>
                                    </>
                                ))}

                            <div data-line-nr={workExperiences.length + 11} class="code-l">
                                <div class="dot">····</div>{"]"}
                            </div>
                            <div data-line-nr={workExperiences.length + 12} class="code-l">
                                <div class="dot">··</div>{"}"}
                            </div>
                            {/* word experience end */}

                            {/* education start */}
                            <div data-line-nr={workExperiences.length + 13} class="code-l">
                                <div class="dot">··</div><span class="propname">education</span>{"() {"}
                            </div>
                            <div data-line-nr={workExperiences.length + 14} class="code-l">
                                <div class="dot">····</div>
                                <div class="methods">return</div> {"["}
                            </div>
                            <div data-line-nr={workExperiences.length + 15} class="code-l">
                                <Reveal>
                                    <div class="dot">······</div>{"{"}<span class="string">'2021-2024'</span> : <span class="string">
                                        'FPT University Vietnam - Bachelor of Engineering,
                                        Software Engineer (IT)'</span>{"}"},
                                </Reveal>

                            </div>
                            <div data-line-nr={workExperiences.length + 16} class="code-l">
                                <div class="dot">····</div>{"]"}
                            </div>
                            <div data-line-nr={workExperiences.length + 17} class="code-l">
                                <div class="dot">··</div>{"}"}
                            </div>
                            {/* education end */}

                            {/* skill start */}
                            <div data-line-nr={workExperiences.length + 18} class="code-l">
                                <div class="dot">··</div><span class="propname">skills</span>{"() {"}
                            </div>
                            <div data-line-nr={workExperiences.length + 19} class="code-l">
                                <div class="dot">····</div>
                                <div class="methods">return</div> {"["}

                                <span class="string skill-sets">
                                    <Reveal>
                                        {programmingLanguages.map((item) => (
                                            <span>'{item}', </span>
                                        ))}
                                        {databases.map((item) => (
                                            <span>'{item}', </span>
                                        ))}
                                        {webDevelopment.map((item) => (
                                            <span>'{item}', </span>
                                        ))}
                                        {appDevelopment.map((item) => (
                                            <span>'{item}', </span>
                                        ))}
                                        {others.map((item) => (
                                            <span>'{item}', </span>
                                        ))}
                                        {designTools.map((item) => (
                                            <span>'{item}', </span>
                                        ))}
                                        <span className="cursor"></span>
                                    </Reveal>
                                </span>


                            </div>
                            <div data-line-nr={workExperiences.length + 20} class="code-l">
                                <div class="dot">····</div>{"]"}
                            </div>
                            <div data-line-nr={workExperiences.length + 21} class="code-l">
                                <div class="dot">··</div>{"}"}
                            </div>
                            {/* skill end */}

                            {/* certificates and awards start */}
                            <div data-line-nr={workExperiences.length + 22} class="code-l">
                                <div class="dot">··</div><span class="propname">certificatesAwards</span>{"() {"}
                            </div>

                            <div data-line-nr={workExperiences.length + 23} class="code-l">
                                <div class="dot">····</div>
                                <div class="methods">return</div> {"["}
                            </div>
                            {
                                certificates.map((certificate, index) => (
                                    <>
                                        <div data-line-nr={workExperiences.length + index + 24} class="code-l">
                                            {
                                                certificate.link == null ?
                                                    <Reveal delay={index / 6}>
                                                        <div class="dot">······</div>{"{"}<span class="string">'{certificate.name}'</span> : <span class="string">
                                                            '{certificate.description}'</span>{"}"},
                                                    </Reveal>
                                                    :
                                                    <Reveal delay={index / 6}>
                                                        <div class="dot">······</div>{"{"}<span class="string">'<a rel="noreferrer" target="_blank" href={certificate.link} style={{ color: "inherit" }}>{certificate.name}</a>'</span> : <span class="string">

                                                            '{certificate.description}'</span>{"}"},
                                                    </Reveal>
                                            }
                                        </div>
                                    </>
                                ))}
                            <div data-line-nr={workExperiences.length + certificates.length + 24} class="code-l">
                                <div class="dot">····</div>{"]"}
                            </div>
                            <div data-line-nr={workExperiences.length + certificates.length + 25} class="code-l">
                                <div class="dot">··</div>{"}"}
                            </div>
                            {/* certificates and awards end */}
                            <div data-line-nr={workExperiences.length + certificates.length + 26} class="code-l">{"}"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}