import React, { useState, useEffect } from "react";
import "./About.css"; // Import your CSS file for custom styles
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useInview, useAnimation } from "framer-motion";
import RevealWithSlide from "../../utils/RevealWithSlide";
import Reveal from "../../utils/Reveal";

export default function About() {



    return (
        <div style={{ marginTop: "30px" }} class="mx-auto col-sm-7 col-md-7 col-9">

            <div class="timeline alt"/>
            <small class="section-bullet section-title bullet-title alt">About /{">"}</small>
            <div className="row avatar-intro-container">
                <div className="col-md-5 avatar-container">
                    <div className="avatar-border"></div>
                    <div className="avatar">
                        <Reveal>
                            <img className="avatar-image" src="assets/img/avatar.jpg" />

                        </Reveal>
                    </div>
                </div>
                <div className="col-md-7 intro-container">
                    <RevealWithSlide delay={0}>
                        <h2 className="intro-heading">A brief intro, who am i?</h2>
                    </RevealWithSlide>
                    <Reveal delay={0.25}>
                        <p className="intro-paragraph">I am a versatile software engineer, adept in crafting innovative solutions for large-scale systems, ERP, and MES in Hanoi, Vietnam.</p>
                    </Reveal>
                    <Reveal delay={0.5}>
                        <p className="intro-paragraph">I’m also a dedicated UX/UI designer. I blend technical expertise with design sensibilities to create solutions that not only work seamlessly but also offer a visually appealing and user-friendly experience.</p>
                    </Reveal>
                    <Reveal delay={0.75}>
                        <p className="intro-paragraph">Beyond the lines of codes, I enjoy writing blog, making Youtube and Tiktok contents about productivity, developing and designing. </p>
                    </Reveal>
                </div>
            </div>

            

        </div>

    );

}