import React, { useRef, useState } from 'react';
import './Slider.css';

const Slider = ({ items }) => {
    const trackRef = useRef(null);

    const handleOnDown = e => {
        if (trackRef.current === null) return;
        trackRef.current.dataset.mouseDownAt = e.clientX;
    };

    const handleOnUp = () => {
        if (trackRef.current === null) return;
        trackRef.current.dataset.mouseDownAt = "0";
        trackRef.current.dataset.prevPercentage = trackRef.current.dataset.percentage;
    };

    const handleOnMove = e => {
        if (trackRef.current === null || trackRef.current.dataset.mouseDownAt === "0") return;

        const mouseDelta = parseFloat(trackRef.current.dataset.mouseDownAt) - e.clientX,
            maxDelta = window.innerWidth / 2;

        const percentage = (mouseDelta / maxDelta) * -100,
            nextPercentageUnconstrained = parseFloat(trackRef.current.dataset.prevPercentage) + percentage,
            nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

        trackRef.current.dataset.percentage = nextPercentage;

        trackRef.current.animate([
            { transform: `translate(${nextPercentage}%, -50%)` }
        ], { duration: 1200, fill: "forwards" });

        const images = trackRef.current.getElementsByClassName("image");
        for (let i = 0; i < images.length; i++) {
            images[i].animate([
                { objectPosition: `${100 + nextPercentage}% center` }
            ], { duration: 1200, fill: "forwards" });
        }
    };

    // Adding event listeners only when mouse enters the image-slider region
    const handleMouseEnter = () => {
        window.addEventListener('mousedown', handleOnDown);
        window.addEventListener('touchstart', e => handleOnDown(e.touches[0]));
        window.addEventListener('mouseup', handleOnUp);
        window.addEventListener('touchend', e => handleOnUp(e.touches[0]));
        window.addEventListener('mousemove', handleOnMove);
        window.addEventListener('touchmove', e => handleOnMove(e.touches[0]));
    };

    // Removing event listeners when mouse leaves the image-slider region
    const handleMouseLeave = () => {
        window.removeEventListener('mousedown', handleOnDown);
        window.removeEventListener('touchstart', e => handleOnDown(e.touches[0]));
        window.removeEventListener('mouseup', handleOnUp);
        window.removeEventListener('touchend', e => handleOnUp(e.touches[0]));
        window.removeEventListener('mousemove', handleOnMove);
        window.removeEventListener('touchmove', e => handleOnMove(e.touches[0]));
    };

    const [clickedIndex, setClickedIndex] = useState(-1);

    const handleCardClick = (index) => {
        if (clickedIndex === index) {
            setClickedIndex(-1); // Unselect if clicked again
        } else {
            setClickedIndex(index);
        }
    };

    return (
        <div id='image-track-wrapper'>
            <div
                id="image-track"
                ref={trackRef}
                data-mouse-down-at="0"
                data-prev-percentage="0"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >

                {items
                    .map((item, index) => {
                        return (
                            <div key={index} className={`card__ ${clickedIndex === index ? 'clicked' : ''}`} onClick={() => handleCardClick(index)}>
                                <div className='card-front__'>
                                    <img alt={item.title} className="image" src={item.image} draggable="false" />
                                </div>

                                <div class="card-back__">
                                    <h2>{item.title}</h2>
                                    <div style={{ width: "100%", height: "5px", backgroundColor: "#1559ED", marginBottom: "10px" }}></div>
                                    <h5>{item.technology.map(t => {
                                        return (
                                            <strong>{t}. </strong>
                                        )
                                    })}</h5>
                                        <p style={{ marginTop:"20px" }}>
                                            {item.desc}
                                        </p>
                                        <center>
                                            <a target='_blank' rel="noreferrer" href={item.link} className='btn btn-primary'>View more</a>
                                        </center>

                                </div>
                            </div>
                        )
                    })
                }



            </div>
        </div>

    );
}

export default Slider;