import Header from './components/nav/HeaderContent';
import Start from './components/start/Start';
import About from './components/about/AboutContent';
import Project from './components/projects/ProjectContent';
import Contact from './components/contact/ContactContent';
import './App.css';
import AboutDetails from './components/about/AboutDetails';



function App() {

  return (
    <div>
      <div className='portfolio'>
        <div className='header'><Header /></div>
        <div className='sections'>
          <div className='section start'><Start /></div>

          <div className='section about'>
            <About />
          </div>
          <AboutDetails />
          <div className='section projects'>
            <Project />
          </div>
          <div className='section contact'>
            <Contact />
          </div>
        </div>

      </div>
    </div>

  );
}

export default App;
