import React, { useState, useEffect, useRef } from "react";
import "./Contact.css"; // Import your CSS file for custom styles
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useInview, useAnimation } from "framer-motion";
import RevealWithSlide from "../../utils/RevealWithSlide";
import Reveal from "../../utils/Reveal";
import ContactLink from "./ContactLink";
import emailjs from '@emailjs/browser';





export default function Contact() {

    const form = useRef();

    const [success, setSuccess] = useState(null);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_2oljp1b', 'template_fzry24h', form.current, {
                publicKey: '48IYqzyXN9TB5sWyl',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSuccess(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setSuccess(false);
                },
            );
    };


    return (
        <div class="mx-auto col-sm-7 col-md-7 col-9">
            <div class="timeline alt" />
            <br></br>
            <small class="section-bullet section-title bullet-title alt">Contact /{">"}</small>

            <div className="contact-form-container">
                <div className="row">
                    <div className="col-md-6 form-information">
                        <RevealWithSlide>
                            <h1>Let's work together</h1>
                        </RevealWithSlide>
                        <h3>Email</h3>
                        <RevealWithSlide delay={0.2}>
                            <p>business@codewithdt.com</p>
                        </RevealWithSlide>

                        <h3>Address</h3>

                        <RevealWithSlide delay={0.4}>
                            <p>43/98/20 Cổ Nhuế, Bắc Từ Liêm, Hà Nội</p>
                        </RevealWithSlide>
                        <h3>Phone</h3>
                        <RevealWithSlide delay={0.6}>
                            <p>(+84) 79 6428 094</p>
                        </RevealWithSlide>
                    </div>
                    <div className="col-md-6 form-inputs">

                        <form ref={form} onSubmit={sendEmail}>
                            <input type="text" required placeholder="Name" name="name" />
                            <input type="email" required placeholder="Email" name="email" />
                            <textarea rows={8} placeholder="Message" name="message" />
                            <button type="submit">Submit</button>
                            {success && "Your message has been sent. I'll get back to you as soon as possible :)"}
                            {/* {error && "Error"}
                            {success && "Success"} */}
                        </form>
                    </div>
                </div>
            </div>




            <ContactLink />

            <div style={{ position:"absolute", bottom:"0px" }}>
                <p>@{new Date().getFullYear()} Made by <span style={{ color: "#1559ED" }}>CodeWithDT</span> V1.0</p>
            </div>


        </div>
    );
}