import React, { useState, useEffect } from "react";
import "./Start.css"; // Import your CSS file for custom styles
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useInview, useAnimation } from "framer-motion";
import RevealWithSlide from "../../utils/RevealWithSlide";

export default function Start() {

    const [visible, setVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [activeNavItem, setActiveNavItem] = useState(null);

    //for the develop effects
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Websites", "Mobile Apps", "Games", "ERP", "Webapps", "UX/UI", "Contents"];
    const [text, setText] = useState("");
    const [delta, setDelta] = useState(300 - Math.random() * 50);
    const period = 1500;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => { clearInterval(ticker) }
    }, [text]);

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta / 2);
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === "") {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(100);
        }
    }

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setVisible(currentScrollPos <= 0 || currentScrollPos < prevScrollPos);
        setPrevScrollPos(currentScrollPos);
    };


    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    const handleSetActive = (to) => {
        setActiveNavItem(to);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);
    return (
        <div class="start-container mx-auto col-sm-7 col-md-7 col-9">
            <div class="timeline">
                <div class="bullet" />
                <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={10}
                    onSetActive={() => handleSetActive("about")}
                    className="cursor-pointer"
                >
                    <div class="scroll-bottom scale-hover">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 30" xmlSpace="preserve">
                            <path fill="#988E9F" d="M9,30c-5,0-9-4.2-9-9.4V9.4C0,4.2,4,0,9,0s9,4.2,9,9.4v11.3C18,25.8,14,30,9,30z M16.2,9.4
c0-4.2-3.2-7.5-7.2-7.5S1.8,5.3,1.8,9.4v11.3c0,4.1,3.2,7.5,7.2,7.5s7.2-3.4,7.2-7.5V9.4z M9.2,12.8c-0.5,0-0.9-0.4-0.9-0.9V7.1
c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v4.7C10.1,12.3,9.7,12.8,9.2,12.8z" class="st0"></path>
                        </svg>
                        <br />
                        <span>SCROLL</span>
                    </div>
                </Link>

            </div>
            <span>
                <div class="">
                    <span class=""></span>
                    <small id="first-element" class="section-bullet section-title mb-3 pb-3"><RevealWithSlide delay={0.5}>Start /{">"}</RevealWithSlide></small>
                </div>
            </span>
            <br />
            <div class="start-heading start-greeting">
                <span class=""></span>
                <h1 class="">
                    <RevealWithSlide delay={0}>
                        Hola, my name is <span style={{ color: "#1559ED", fontWeight: "600" }}>Đào Duy Thái</span>
                    </RevealWithSlide>

                </h1>
            </div>
            <br />
            <div class="start-heading"><span class=""></span>
                <h1 class="subtitle mt-0 ws-nowarp">
                    <RevealWithSlide delay={0.25}>
                        <h1 className="subtitle-specification">i <span style={{ fontWeight: "350" }} className="fst-italic">design</span> and develop <span className=""><br></br>{text}</span><span style={{ color: "#D5D5EB" }}>│</span></h1>
                    </RevealWithSlide>
                </h1>
            </div><br />
            <div class="start-subtitle"><span class=" white"></span>
                <p class="color-gray mt-2 pb-3 ">
                    <RevealWithSlide delay={1}>Want to know more? Let me show You...</RevealWithSlide>
                </p>
            </div>

        </div>

    );

}